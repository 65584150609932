
import RouteNames from '@/ui/router/route-names'
import UiUtils from '@/ui/util/ui-utils'
import dayjs from 'dayjs'
import Vue from 'vue'
import Component from 'vue-class-component'
import GuideTodoList from '../common/GuideTodoList.vue'
import ALL_STEPS from './ita-guide-steps'

@Component({
  head: {
    title: UiUtils.translateTitle('label.ita'),
  },
  components: {
    GuideTodoList,
  },
})
export default class ItaScreen extends Vue {
  minDate = dayjs().subtract(1, 'year')
  maxDate = dayjs()

  allSteps = ALL_STEPS

  mounted() {
    UiUtils.scrollByRouteParams(this)
  }

  showDateExplanation() {
    this.$dialog.alert({
      title: 'Date of receiving your ITA',
      message: 'Your Express Entry invitation to apply is valid for 60 days only.',
    })
  }

  goToNextStep() {
    this.$router.push({ name: RouteNames.GUIDE_PR })
  }
}
